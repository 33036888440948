import { defineStore } from 'pinia';
import axios from 'axios';

// import { useRoute } from 'vue-router'
// const route = useRoute();
// const appProvided = inject('appProvided'); // this assumes `app.provide('appProvided', 'value')` was called

//import { inject } from 'vue';
//const $notify = inject('$notify');

export default defineStore('main', {
    // Ваше состояние
    state: () => {
        return {
            me: {
                tariff: {},
                band: {},
                school: {},
                class: {}, // @todo Refactoring
                course: {},
                quiz_training_hit_ids: [],
                //adjustments: {}, // to use $store.adjustments
            },
            adjustments: {},// @todo Для сторінки авторизації
            // @note Це буде обраний вчителем клас
            class: {
                course: {}
            },
            quizzes: [],
            teams: [],
            steps: {},
            //forbiddenAccessKey: '',
            //user: useLocalStorage('pinia/auth/login', 'bob'),
        }
    },

    // Гетери для computed properties
    getters: {
        //firstName: (state) => state.firstName,
        accessKey: () => sessionStorage.getItem('accessKey') || localStorage.getItem('accessKey'),
        isAuthenticated() { return !!(this.accessKey && this.me.id) },
        isRaspberryMode() { return process.env.VUE_APP_IS_RASPBERRY_MODE },
        isDefense() {
            return this.me.tariff.is_defense;
        },
        isSafety() {
            return this.me.tariff.is_safety;
        },
        defenseSubjectRoute() {
            if (this.class.course.level === '10') return {name: 'subject-id', params: {id: 47}};
            if (this.class.course.level === '11') return {name: 'subject-id', params: {id: 48}};
            return {name: 'subjects'};
        },
        defenseFieldTrainingRoute() {
            if (this.class.course.level === '10') return {name: 'subject-id', params: {id: 51}};
            if (this.class.course.level === '11') return {name: 'subject-id', params: {id: 52}};
            return {name: 'subjects'};
        },
        isGrantDiplomaForTester() {
            const params = new URLSearchParams(location.search);
            const isGrantDiplomaForTester = ['1', 'true'].includes(params.get('is_grant_diploma_for_tester'));
            return this.me.is_tester && isGrantDiplomaForTester;
        },
    },

    // Методи для асинхронних операцій:
    actions: {
        async loadAdjustments() {
            console.log('loadAdjustments');
            await axios.get('/adjustments').then(resp => {
                this.adjustments = resp.data.adjustments;
            });
        },
        async auth(accessKey) {
            accessKey = accessKey || this.accessKey;
            await axios.post('teacher/auth', {accessKey: accessKey}, {headers: {'X-Access-Key': accessKey}}).then(res => {
                if (accessKey !== sessionStorage.getItem('accessKey')) {
                    localStorage.setItem('accessKey', accessKey);
                }
            }).catch(err => {
                console.log(err);

                if (sessionStorage.getItem('accessKey')) {
                    sessionStorage.removeItem('accessKey');
                } else {
                    localStorage.removeItem('accessKey');
                }

                // let reason = err.response?.data?.message || err.response?.message || err.message;
                // if (err.code === 'ERR_NETWORK') reason = `Немає зв’язку з сервером. Можливо виконуються вимушені технічні роботи і доведеться дещо зачекати.<br>Reason: ${err.code}: ${reason}`;
                // this.$notify.fail(`<b>Ой!</b> Не вдалось авторизуватись!<br>${reason}`, {/*title: `Ой!`*/}); // Невдача!
            });
        },
        async init() {
            if (this.accessKey && !this.me.id) {
                await this.loadMe();
            }
        },
        async loadMe() {
            const resp = await axios.get(`teacher/me`)
            this.me = resp.data.me;
            this.class = resp.data.me.class;
            //this.adjustments = resp.data.me.adjustments;
            return this.me;
        },
        logout() {
            sessionStorage.removeItem('accessKey');
            localStorage.removeItem('accessKey');
            location.href = '/auth';
        },
        generateSessionAuthUrl(accessKey) {
            return location.origin + `?s=${accessKey}`;
        },
        async loadActualQuiz(query) {
            query = query || {is_safety: this.me.tariff?.is_safety ? 1 : 0};
            return await axios.get(`teacher/quizzes/actual`, {params: query}).then(resp => resp.data.quiz);
        },
        async loadQuiz(quiz_hash) {
            return await axios.get(`teacher/quizzes/${quiz_hash}`).then(resp => resp.data.quiz);
        },
        async loadQuizzes(query) {
            //if (this.quizzes.length) return this.quizzes;
            query = query || {};
            return this.quizzes = await axios.get('teacher/quizzes', {params: query}).then(resp => resp.data.quizzes);
        },
        async loadTeams(query) { // {'with-quiz-game': 'QuIzHash'}
            query = query || {};
            return this.teams = await axios.get(`teacher/teams`, {params: query}).then(resp => resp.data.teams);
        },
        async resetTeams() {
            this.teams = [];
        },
        async quizGameInit(quizHash, teamHash) {
            return axios.get(`quiz/game/${quizHash}/${teamHash}/init`).then(resp => resp.data);
        },
        async recalculateTeamScore(teamHash) {
            return axios.get(`quiz/teams/${teamHash}/recalculate`).then(resp => resp.data);
        },
        open(url) {
            window.open(url);
        },
        pluralize(count, words) { // ['зірку', 'зірки', 'зірок']
            const cases = [2, 0, 1, 1, 1, 2];
            return /*count + ' ' + */words[ (count % 100 > 4 && count % 100 < 20) ? 2 : cases[ Math.min(count % 10, 5)] ];
        },
        selectNodeText(el) {
            if (!el) return;
            const range = document.createRange();
            range.selectNodeContents(el);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        },
        copyText(text) {
            const textArea = document.createElement('textarea');
            textArea.style.height = '1px';
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('Unable to copy to clipboard', err);
            }

            document.body.removeChild(textArea);
        },
        slugify(str) {
            // @todo Implement slugify()
        },
        callEveryMinutes(key, minutes, callback) { // @todo Move to helpers/throttle.js
            const passedMinutes = parseInt((new Date().getTime() - new Date(parseInt(localStorage.getItem(key))).getTime()) / 1000 / 60);
            if (isNaN(passedMinutes) || passedMinutes >= minutes) {
                sessionStorage.setItem(key, new Date().getTime().toString());
                callback();
            }
        },
        requireScript(src, onload) {
            const script = document.createElement('script');
            //script.defer = true;
            script.src = src;
            script.onload = onload;
            document.body.appendChild(script);
        },
        requireImaskJs(onload) {
            // @see https://imask.js.org/guide.html#masked-pattern
            window.IMask ? onload() : this.requireScript('https://unpkg.com/imask', onload);
        },
        requireQRCode(onload) {
            // @see https://github.com/davidshimjs/qrcodejs
            window.QRCode ? onload() : this.requireScript('https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js', onload);
        },
        /*requireMomentjs(onload) {
            const script = document.createElement('script');
            script.defer = true;
            script.src = 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.30.1/moment.min.js';
            script.onload = onload;
            document.body.appendChild(script);
            //console.log('momentjs onload:', window.moment().add(1, 'days').toString());
        },*/
        /*async loadExample({ state, commit }, someVal) {
            const res = await api.user.load(id);
            commit('updateSome', res);
        }*/
    },
    // // Мутации для изменения состояния
    // mutations: {
    //     /*updateSome (state, payload) {
    //         state.firstName = payload.firstName
    //     },*/
    // }
})
